import { Component } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

import {
  faFacebookSquare,
  faGooglePlus,
} from "@fortawesome/free-brands-svg-icons";

import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { Router, NavigationEnd } from "@angular/router";

//declare gives Angular app access to ga function
declare let gtag: Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  faFacebook = faFacebookSquare;
  faGoogle = faGooglePlus;
  faPhone = faPhoneAlt;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        console.log(event.urlAfterRedirects);
        gtag("config", "UA-164511936-1", {
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }
}
