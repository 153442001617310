<div style="height: calc(100vh - 100px)">
  <mat-toolbar>
    <mat-toolbar-row>
      <button
        mat-icon-button
        (click)="sidenav.toggle()"
        fxShow="true"
        fxHide.gt-sm
      >
        <mat-icon>menu</mat-icon>
      </button>
      <span class="logo"
        ><img src="../assets/logo.svg" alt="Venecia Logo"
      /></span>

      <span class="menu-spacer"></span>
      <a class="call-small-btn" href="tel:9727849063"
        ><fa-icon [icon]="faPhone"></fa-icon
      ></a>
      <div fxShow="true" fxHide.lt-md>
        <a routerLink="/" mat-button>Home</a>
        <a routerLink="/menu" mat-button>Menu</a>
        <a routerLink="/contact" mat-button>Contact</a>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-sidenav-container fxFlexFill class="sidenav-container">
    <mat-sidenav #sidenav class="sidenav" (click)="sidenav.toggle()">
      <mat-nav-list>
        <a routerLink="/" mat-list-item>Home</a>
        <a routerLink="/menu" mat-list-item>Menu</a>
        <a routerLink="/contact" mat-list-item>Contact</a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content fxFlexFill>
      <router-outlet></router-outlet>
      <footer>
        <p>2021 &copy; Venecia Italian Restaurant Farmersville, TX</p>
        <div class="social">
          <a
            class="facbook-btn"
            target="_blank"
            href="https://www.facebook.com/Venecia-Italian-restaurant-1833714730204084/"
            ><fa-icon [icon]="faFacebook"></fa-icon
          ></a>
        </div>
      </footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
